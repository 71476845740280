import { getToken, getAccessToken } from './auth';
import Axios from 'axios';
import config from './config';

export function postFile(body, fileType) {
  return new Promise((resolve, reject) => {
    let endpoint = '/post' + fileType;
    const TIMEOUT_LIMIT = 60 * 3 * 1000;
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + endpoint,
          timeout: TIMEOUT_LIMIT,
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function requestPresignedUrlForFile(requestBody) {
  return new Promise((resolve, reject) => {
    const TIMEOUT_LIMIT = 60 * 3 * 1000;
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/presignedUrl',
          timeout: TIMEOUT_LIMIT,
          headers: {
            Authorization: 'Bearer ' + token,
            "Content-Type": getMimeTypeForFile(requestBody.key),
          },
          data: {
            bucket: requestBody.bucket,
            key: requestBody.key,
            "Content-Type": getMimeTypeForFile(requestBody.key),
          },
        })
          .then(response => {
            console.info('URL generation successful');
            return resolve(response);
          })
          .catch(err => {
            console.error('An error occurred when requesting the presigned url', err);
            return reject(err);
          });
      })
  })
}

export function uploadFileWithPresignedUrl(url, filename, fileData) {
  return new Promise((resolve, reject) => {
    Axios({
      method: 'PUT',
      url: url,
      headers: {
        "Content-Type": getMimeTypeForFile(filename),
      },
      data: fileData,
    })
      .then(response => {
        console.info('File upload successful');
        return resolve(response);
      })
      .catch(err => {
        console.error('An error occurred when uploading the file to S3', err);
        return reject(err);
      });
  });
}

function getMimeTypeForFile(filename) {
  if (filename) {
    if (filename.toLowerCase().indexOf('.xlsx') > -1) {
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    else if (filename.toLowerCase().indexOf('.pdf') > - 1) {
      return 'application/pdf';
    }
    else if (filename.toLowerCase().indexOf('.jpg') > -1 || filename.toLowerCase().indexOf('.jpeg') > -1) {
      return 'image/jpg';
    }
    else if (filename.toLowerCase().indexOf('.png') > -1) {
      return 'image/png';
    }
  }
  return 'application/json';
}

export function postArticle(body) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/articles',
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function getArticle(articleId) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        const headers = {
          Authorization: 'Bearer ' + token,
          "Accept-Language": 'en',
          datatype: "article"
        };
        console.log(headers);
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/articles/' + articleId,
          headers: headers,
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function getNavTables() {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/navigation/mainMenu',
          headers: {
            Authorization: 'Bearer ' + token,
            "Accept-Language": 'en',
            datatype: "mainMenu"
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function postNavigation(body) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/postNavigation',
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function deleteArticle(articleId, language, environment) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'DELETE',
          url: config.apiGateway.URL + '/articles/' + articleId,
          headers: {
            "Access-Control-Expose-Headers": "Accept-Language",
            Authorization: 'Bearer ' + token,
            "Accept-Language": language,
            "environment": environment
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function postTag(body) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/tags',
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function getTags(category) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/tags/' + category,
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export async function getUsergroups(url) {
  return new Promise((resolve, reject) => {
    getAccessToken().then(({ accessToken, ...rest }) => {
      Axios({
        method: 'GET',
        url: url,
        headers: {
          Authorization: accessToken
        }
      })
        .then(response => resolve({ usergroups: response.data, ...rest }))
        .catch(err => {
          console.log("Exception " + err);
          return reject(err);
        });
    })
  })
}

export function getVehicleTrims(model, year, language) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/edge/' + model + '/' + year,
          headers: {
            Authorization: 'Bearer ' + token,
            "Accept-Language": language
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function promoteItem(body) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/promote',
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception in api call: " + err);
            return reject(err);
          });
      })
  })
}

export function promoteMenuIcon(body) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/moveImages',
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Take in 'prod' or 'staging', 'active' or 'archived', and back end
// will translate to sourceEnv/destinationEnv and isArchived=false/true
export function getGlossary(environment, status) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/glossary',
          headers: {
            Authorization: 'Bearer ' + token,
            environment: environment,
            status: status,
            datatype: 'terms'
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception in api call " + err);
            return reject(err);
          });
      })
  })
}

export function getNavigation(page) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/navigation/' + page,
          headers: {
            Authorization: 'Bearer ' + token,
            datatype: page
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export function getSecurityRoles() {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/security',
          headers: {
            Authorization: 'Bearer ' + token,
            datatype: 'security'
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception in api call " + err);
            return reject(err);
          });
      })
  })
}

export function getAlternateVehicleNames() {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/alternateVehicleNames',
          headers: {
            Authorization: 'Bearer ' + token,
            datatype: 'alternateVehicleNames'
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception in api call " + err);
            return reject(err);
          });
      })
  })
}

export function getVehicleModelNames() {
  return new Promise((resolve, reject) => {
    getAlternateVehicleNames()
      .then(response => {
        const vehicleModelNames = response.map(vehicle => vehicle.vehicleModelName);
        resolve(vehicleModelNames);
      }).catch(reject);
  })
}

export function getVehicleS3PathName(modelName) {
  return new Promise((resolve, reject) => {
    getAlternateVehicleNames()
      .then(response => {
        const { s3PathName } = response.find(vehicle => vehicle.vehicleModelName === modelName);
        resolve(s3PathName);
      }).catch(reject);
  })
}

export async function createTerm(body, callback) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/glossary/create',
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            callback();
            return resolve(response);
          })
          .catch(err => {
            callback();
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export async function updateTerm(id, body, callback) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'PUT',
          url: config.apiGateway.URL + '/glossary/' + id,
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            callback();
            return resolve(response);
          })
          .catch(err => {
            callback();
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Archive the staging term with this id AND the associated prod term
export async function archiveTerm(id, callback) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'PUT',
          url: config.apiGateway.URL + '/glossary/archive',
          headers: {
            Authorization: 'Bearer ' + token,
            id: id
          }
        })
          .then(response => {
            callback();
            return resolve(response);
          })
          .catch(err => {
            callback();
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Update the staging term with this id to isArchived: false
export async function restoreTerm(id, callback) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'PUT',
          url: config.apiGateway.URL + '/glossary/restore',
          headers: {
            Authorization: 'Bearer ' + token,
            id: id
          }
        })
          .then(response => {
            callback();
            return resolve(response);
          })
          .catch(err => {
            callback();
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Copy staging term shown on mc-web to prod
export async function promoteTerm(body, callback) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/glossary/promote',
          headers: {
            Authorization: 'Bearer ' + token
          },
          data: body
        })
          .then(response => {
            callback();
            return resolve(response);
          })
          .catch(err => {
            callback();
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Update prod term to isArchived: false
export async function unpromoteTerm(id, callback) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'PUT',
          url: config.apiGateway.URL + '/glossary/unpromote',
          headers: {
            Authorization: 'Bearer ' + token,
            id: id
          },
        })
          .then(response => {
            callback();
            return resolve(response);
          })
          .catch(err => {
            callback();
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

///////////////////////////
//// NOTIFICATIONS API ////
///////////////////////////

export function getAnnouncements(body) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/announcement',
          headers: {
            Authorization: 'Bearer ' + token,
          }, 
          data: {
            function: 'Get All'
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception in api call " + err);
            return reject(err);
          });
      })
  })
}

// Get one notification
export function getAnnouncement(id) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/announcement/' + id,
          headers: {
            Authorization: 'Bearer ' + token,
          }, 
          data: {
            function: 'Get All',
            params: {
              id: id
            }
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception in api call " + err);
            return reject(err);
          });
      })
  })
}

// Publish notification to dev/staging
export async function publishNotification(body) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/announcement',
          headers: {
            Authorization: 'Bearer ' + token,
          },
          data: {
            "function": "Publish",
            "params": {
              "category": body.category,
              "icon": body.icon,
              "title" : body.title,
              "message" : body.message, 
              "isPush": body.isPush,
              "redirectPath": body.redirectPath,
            }
          }
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Edit notification in dev/staging
export async function editNotification(body) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/announcement',
          headers: {
            Authorization: 'Bearer ' + token,
          },
          data: {
            "function": "Edit",
            "params": {
              "id": body.id,
              "createdDate": body.createdDate,
              "category": body.category,
              "icon": body.icon,
              "title" : body.title,
              "message" : body.message, 
              "isPush": body.isPush,
              "lastModifiedDate": body.lastModifiedDate,
              "expirationDate": body.expirationDate,
              "isScheduled": body.isScheduled,
              "scheduledDate": body.scheduledDate,
              "editContent": body.editContent || false,
              "redirectPath": body.redirectPath,
            }
          }
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Remove notification from prod/test and mark isArchived in dev/staging
export async function archiveNotification(id) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/announcement',
          headers: {
            Authorization: 'Bearer ' + token,
          },
          data: {
            "function": "Delete",
            "params": {
              "id": id,
            }
          }
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Promote notification to prod/test and update isPromoted and promotedDate in dev/staging
export async function promoteNotification(id, expirationDate) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/announcement',
          headers: {
            Authorization: 'Bearer ' + token,
          },
          data: {
            "function": "Promote",
            "params": {
              "id": id,
              "expirationDate": expirationDate
            }
          }
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Get notification categories
export function getNotificationCategories() {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'GET',
          url: config.apiGateway.URL + '/notificationCategories',
          headers: {
            Authorization: 'Bearer ' + token,
            datatype: 'notificationCategories'
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception in api call " + err);
            return reject(err);
          });
      })
  })
}

///////////////////////////
// SCHEDULED CONTENT API //
///////////////////////////
// These should be made reusable for other content types

// Schedule notification to be promoted to prod/test
export async function scheduleNotification(notificationData, scheduledTime) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/scheduledContent',
          headers: {
            Authorization: 'Bearer ' + token,
          },
          data: {
            "function": "Add",
            "params": {
              "contentId": notificationData.id,
              "scheduledTime": scheduledTime,
              "contentType": "Announcement",
              "title": notificationData.title,
              "status": "Scheduled",
              "createdTimestamp": new Date(),
              "editedTimestamp": notificationData.lastModifiedDate
            }
          }
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

export async function removeScheduledContent(notificationData) {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/scheduledContent',
          headers: {
            Authorization: 'Bearer ' + token,
          },
          data: {
            "function": "Delete",
            "params": {
              "contentId": notificationData.id,
              "scheduledTime": notificationData.scheduledDate
            }
          }
        })
          .then(response => {
            return resolve(response);
          })
          .catch(err => {
            console.log("Exception " + err);
            return reject(err);
          });
      })
  })
}

// Get scheduled content
export function getScheduledContent() {
  return new Promise((resolve, reject) => {
    getToken()
      .then(token => {
        Axios({
          method: 'POST',
          url: config.apiGateway.URL + '/scheduledContent',
          headers: {
            Authorization: 'Bearer ' + token,
          }, 
          data: {
            function: 'GetFuture',
          }
        })
          .then(response => {
            return resolve(response.data);
          })
          .catch(err => {
            console.log("Exception in api call " + err);
            return reject(err);
          });
      })
  })
}